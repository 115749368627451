import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import mq from 'styles/breakpoints'
import { useAuth0 } from 'utils/react-auth0-spa'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import UserContext from 'context/user/UserContext'
import Loading from 'components/Loading'
import {
  LineBreak,
  SubscriptionsContainer,
  SectionIcon,
  GradientButton,
} from 'components/Subscriptions/PricingPlansComponents.js'
import { trackClickEvent } from 'utils/digitalGoAnalytics'

const DigitalGoFluentWorldsMonthly = ({ subscriptionInfo }) => {
  const DIGITAL_GO_REDEEM_PATH = '/digital-go/redeem/'
  const INACTIVE_SUBSCRIPTION = 'inactive'
  const ACTIVE_SUBSCRIPTION = 'active'
  const TRIALING_SUBSCRIPTION = 'trialing'

  const {
    fetchAvailableSitePlans,
    subscriptions,
    cancelDigitalGoSubscription,
  } = useContext(SubscriptionContext)
  const { productAccess, fetchUserProductAccess } = useContext(UserContext)
  const { isAuthenticated, user } = useAuth0()
  const { t } = useTranslation('subscriptionProducts')
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(
    false,
  )
  const [isRedirectLoading, setIsRedirectLoading] = useState(false)

  // when the user is logged in and subscriptions haven't yet been fetched then fetch the available site plans
  useEffect(() => {
    if (isAuthenticated && subscriptions.length === 0) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, productAccess])

  const SubscriptionOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40rem;
    border: 2px solid #f5f5f5;
    padding: 2rem;

    ${mq['xxl']} {
      margin-top: 4rem;
    }

    ${mq['md']} {
      width: 28rem;
      margin-left: 0;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 3.2rem;
      color: #213054;
      margin-top: 3rem;
    }

    h3 {
      font-size: 4.8rem;
      font-weight: 300;
      margin-top: 1rem;
      color: black;
    }

    h6 {
      color: #b9b9b9;
      font-size: 1.7rem;
      margin-bottom: 1.5rem;
    }

    ul {
      list-style: none;
      font-size: 2rem;
      margin-top: 1.5rem;
      width: 25rem;
      height: 9rem;
    }

    p {
      font-size: 2.1rem;
      text-align: center;
      margin-top: 1.5rem;
      width: 25rem;
    }

    h5 {
      color: #b9b9b9;
      font-weight: 700;
      font-size: 1.8rem;
      margin-top: 2rem;
    }

    button {
      text-decoration: none;
      font-weight: bold;
      width: 28rem;

      &:nth-of-type(2) {
        margin-top: 2rem;
      }
    }

    ${mq['md']} {
      h4 {
        font-size: 2.7rem;
      }

      h3 {
        font-size: 4rem;
      }

      h6 {
        font-size: 1.5rem;
      }

      ul {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.8rem;
      }

      h5 {
        font-size: 1.5rem;
      }

      button {
        font-size: 2.2rem;
      }
    }

    outline: 2px solid #0080fa;
    background-color: rgb(250, 250, 250);
    outline-offset: 0px;
    border: none;
  `

  const LineItem = styled.div`
    display: flex;
    font-size: 2.3rem;
    width: 100%;
    margin-bottom: 1rem;
  `

  const Label = styled.div`
    text-align: left;
    padding-left: 1rem;
  `

  const Value = styled.div`
    padding-left: 1rem;
  `

  const PromoTextOne = styled.div`
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0.8rem 0;
  `

  const PromoTextTwo = styled.div`
    font-size: 2rem;
    font-weight: bold;
  `

  const convertPrice = (decimalPrice) => {
    const decimalPriceStr = decimalPrice.toString()
    const length = decimalPriceStr.length
    const dollar = decimalPriceStr.substring(0, length - 2)
    const cents = decimalPriceStr.slice(-2)

    return dollar + '.' + cents + '€'
  }

  const onClickCancel = async () => {
    if (isAuthenticated && productAccess && productAccess.fluentworlds) {
      setCancelSubscriptionLoading(true)
      const stripeSubscriptionId = await cancelDigitalGoSubscription()

      if (stripeSubscriptionId) {
        setIsRedirectLoading(true)
        window.alert(t('cancelSuccess'))
        window.location.reload(false)
      }
    }
  }

  const onClickNavigateToPurchase = () => {
    trackClickEvent('accountAbbonati', user.sub)
    navigate(DIGITAL_GO_REDEEM_PATH)
  }

  const convertToHumanReadableDate = (epochTimestamp) => {
    return new Date(epochTimestamp * 1000).toLocaleDateString()
  }

  const translateStatus = (status) => {
    if (status === INACTIVE_SUBSCRIPTION) {
      return t('inactive')
    } else if (status === ACTIVE_SUBSCRIPTION) {
      return t('activeSubscription')
    } else if (status === TRIALING_SUBSCRIPTION) {
      return t('trialingSubscription')
    } else {
      return ''
    }
  }

  const isCancelButtonDisabled =
    subscriptionInfo.stripeSubscriptionCancelAt !== null

  const renderSubscriptionData = () => {
    if (
      subscriptionInfo &&
      subscriptionInfo.stripeSubscriptionStatus !== INACTIVE_SUBSCRIPTION
    ) {
      return (
        <>
          <LineItem style={{ marginTop: '1rem' }}>
            <Label>{t('status')}: </Label>
            <Value>
              {translateStatus(subscriptionInfo.stripeSubscriptionStatus)}
            </Value>
          </LineItem>
          <LineItem>
            <Label>{t('priceAmount')}: </Label>
            <Value>
              {convertPrice(subscriptionInfo.stripeSubscriptionPriceAmount)}
            </Value>
            <Value>{subscriptionInfo.stripeSubscriptionInterval}</Value>
          </LineItem>
          <LineItem>
            <Label>{t('createdAt')}: </Label>
            <Value>
              {convertToHumanReadableDate(
                subscriptionInfo.stripeSubscriptionCreatedAt,
              )}
            </Value>
          </LineItem>
          <LineItem>
            <Label>{t('trialEnd')}: </Label>
            <Value>
              {subscriptionInfo.stripeSubscriptionTrialEnd
                ? convertToHumanReadableDate(
                    subscriptionInfo.stripeSubscriptionTrialEnd,
                  )
                : t('inactive')}
            </Value>
          </LineItem>
          <LineItem>
            <Label>
              {!subscriptionInfo.stripeSubscriptionCancelAt
                ? t('periodEnd')
                : t('cancelAt')}
              :{' '}
            </Label>
            <Value>
              {!subscriptionInfo.stripeSubscriptionCancelAt
                ? convertToHumanReadableDate(
                    subscriptionInfo.stripeSubscriptionCurrentPeriodEnd,
                  )
                : convertToHumanReadableDate(
                    subscriptionInfo.stripeSubscriptionCancelAt,
                  )}
            </Value>
          </LineItem>

          <GradientButton
            onClick={() => onClickCancel()}
            disabled={isCancelButtonDisabled}
          >
            {t('cancel')}
          </GradientButton>
        </>
      )
    } else if (
      subscriptionInfo &&
      subscriptionInfo.stripeSubscriptionStatus === INACTIVE_SUBSCRIPTION
    ) {
      return (
        <>
          <LineItem style={{ marginTop: '1rem' }}>
            <Label>{t('status')}: </Label>
            <Value>
              {translateStatus(subscriptionInfo.stripeSubscriptionStatus)}
            </Value>
          </LineItem>
          <GradientButton onClick={() => onClickNavigateToPurchase()}>
            {t('buy')}
          </GradientButton>
          <PromoTextOne>{t('dgoPromoOne')}</PromoTextOne>
          <PromoTextTwo>{t('dgoPromoTwo')}</PromoTextTwo>
        </>
      )
    } else {
      return null
    }
  }

  const showLoading = cancelSubscriptionLoading || isRedirectLoading

  return (
    <>
      {showLoading ? (
        <Loading />
      ) : (
        <SubscriptionsContainer>
          <SubscriptionOptionContainer>
            <SectionIcon>
              <StaticImage
                src="../../images/png/icon-fw-sm.png"
                alt="fluentworlds"
                placeholder="none"
                height={100}
              />
            </SectionIcon>
            <h4>FluentWorlds</h4>
            <LineBreak />
            {renderSubscriptionData()}
          </SubscriptionOptionContainer>
        </SubscriptionsContainer>
      )}
    </>
  )
}

export default DigitalGoFluentWorldsMonthly
