import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import DownloadFluentWorlds from 'components/DownloadFluentWorlds'
import Loading from 'components/Loading'
import Footer from 'components/Footer'
import AppLoginHandler from 'components/AppLoginHandler'
import DigitalGoHeader from 'components/digital-go/DigitalGoHeader'
import DigitalGoFluentWorldsMonthly from 'components/Subscriptions/DigitalGoFluentWorldsMonthly'
import {
  USER_AUTHENTICATED_QUERY_PARAM,
  trackRegistrationEvent,
} from 'utils/digitalGoAnalytics'

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
`

const CenteredHeader = styled.h1`
  text-align: center;
`

const Account = () => {
  const DIGITAL_GO_ACCOUNT_PATH = '/digital-go/account/'

  const { user, auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('accountPage')
  const [subscriptionInfo, setSubscriptionInfo] = useState(null)
  const [getSubInfoLoading, setGetSubInfoLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const {
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const { fetchDigitalGoSubInfo, subscriptionStateLoading } = useContext(
    SubscriptionContext,
  )
  const [userAuthenticatedFlag] = useQueryParam('user-auth', StringParam)

  useEffect(() => {
    const queriesLoading =
      userStateLoading || subscriptionStateLoading || getSubInfoLoading
    const dataReady = subscriptionInfo && productAccess && ready
    const safeState = !queriesLoading && dataReady
    setShowLoading(!safeState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userStateLoading,
    getSubInfoLoading,
    subscriptionInfo,
    productAccess,
    ready,
  ])

  useEffect(() => {
    if (!auth0Loading) {
      if (isAuthenticated) {
        fetchUserProductAccess()
      } else {
        loginWithRedirect({
          redirect_uri:
            process.env.GATSBY_WEBSITE_URL +
            DIGITAL_GO_ACCOUNT_PATH +
            USER_AUTHENTICATED_QUERY_PARAM,
          ui_locales: i18n.language.substring(0, 2),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading])

  // fetch the digitial go subscription info once the user is authenticated and productAccess has been fetched
  useEffect(() => {
    const getSubInfo = async () => {
      const userSubInfo = await fetchDigitalGoSubInfo()
      setSubscriptionInfo(userSubInfo)
      setGetSubInfoLoading(false)
    }

    if (
      !auth0Loading &&
      !subscriptionStateLoading &&
      !userStateLoading &&
      isAuthenticated &&
      productAccess &&
      !subscriptionInfo &&
      !getSubInfoLoading
    ) {
      setGetSubInfoLoading(true)
      getSubInfo()
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    userStateLoading,
    isAuthenticated,
    productAccess,
    subscriptionInfo,
    fetchDigitalGoSubInfo,
    getSubInfoLoading,
    setGetSubInfoLoading,
  ])

  useEffect(() => {
    if (
      userAuthenticatedFlag !== undefined &&
      userAuthenticatedFlag &&
      userAuthenticatedFlag === 'true' &&
      user
    ) {
      trackRegistrationEvent(user.sub)
    }
  }, [user, userAuthenticatedFlag])

  return (
    <>
      <Global styles={GlobalStyles} />
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <DigitalGoHeader />
      <AppLoginHandler />
      {showLoading ? (
        <Loading />
      ) : (
        <AccountContainer>
          <CenteredHeader>{t('manageSubscriptions')}</CenteredHeader>
          <DigitalGoFluentWorldsMonthly subscriptionInfo={subscriptionInfo} />
          <DownloadFluentWorlds />
        </AccountContainer>
      )}
      <Footer />
    </>
  )
}

export default Account
